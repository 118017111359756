// halah bihalal 2024
import hb24 from "../../images/news/hb24/halalbihalal2024.jpeg";
import hb241 from "../../images/news/hb24/hb1.jpeg";
import hb242 from "../../images/news/hb24/hb2.jpeg";

// bandara dhoho kediri
import bdk24 from "../../images/news/bandara-kediri2024/thumbnail.png";
import bdk241 from "../../images/news/bandara-kediri2024/add1.png";
import bdk242 from "../../images/news/bandara-kediri2024/add2.png";

// bagi takjil 2024
import bt24 from "../../images/news/bagi-takjil2024/thumbnail.png";
import bt241 from "../../images/news/bagi-takjil2024/add1.png";
import bt242 from "../../images/news/bagi-takjil2024/add2.png";
import bt243 from "../../images/news/bagi-takjil2024/add3.png";
import bt244 from "../../images/news/bagi-takjil2024/add4.png";
import bt245 from "../../images/news/bagi-takjil2024/add5.png";
import bt246 from "../../images/news/bagi-takjil2024/add6.png";
import bt247 from "../../images/news/bagi-takjil2024/add7.png";

// barcode purabaya 2024
import bp24 from "../../images/news/barcode-purabaya2024/bp1.jpeg";
import bp241 from "../../images/news/barcode-purabaya2024/bp2.jpeg";
import bp242 from "../../images/news/barcode-purabaya2024/bp3.jpeg";
import bp243 from "../../images/news/barcode-purabaya2024/bp4.jpeg";
import bp244 from "../../images/news/barcode-purabaya2024/bp5.jpeg";

// dukungan AMJ terhadap purabaya Oktober 2024
import okt24 from "../../images/news/support-purabaya-okt2024/i1.jpg";
import okt241 from "../../images/news/support-purabaya-okt2024/i2.jpg";
import okt242 from "../../images/news/support-purabaya-okt2024/i3.jpg";
import okt243 from "../../images/news/support-purabaya-okt2024/i4.jpg";

export const blogsData = [
    {
        id: 1,
        image: hb24,
        title: "Halal Bihalal PT Alfath Mulia Jaya Perkuat Kebersamaan",
        body: "Memasuki bulan Ramadhan yang penuh berkah, PT Alfath Mulia Jaya mengadakan acara halal bihalal atau buka puasa bersama seluruh anggota perusahaan. Acara yang digelar pada Kamis, 28 Maret 2024 ini berlangsung di Hesdon Estate Kitchen and Lounge, Surabaya mulai pukul 16.00 WIB hingga selesai.:: Acara ini dicetuskan oleh pimpinan perusahaan dengan mengusung tema kebersamaan dan keakraban. Tujuannya untuk mempererat tali silaturahmi antar seluruh karyawan, staf, dan pimpinan perusahaan. Melalui kegiatan buka puasa bersama, diharapkan dapat terjalin komunikasi yang lebih terbuka dan suasana kekeluargaan yang erat di lingkungan PT Alfath Mulia Jaya.:: Acara dimulai dengan sesi berbuka puasa bersama dengan hidangan lezat yang telah disediakan. Suasana akrab dan hangat terasa di ruangan saat seluruh peserta menikmati santap bersama. Terdengar obrolan ringan dan tawa di sana-sini, menandakan keakraban yang mulai terjalin.::Usai berbuka, seluruh peserta melaksanakan salat maghrib berjamaah. Kemudian, acara ditutup dengan sesi ramah tamah yang memungkinkan setiap orang untuk bersalaman, berpelukan, dan saling memaafkan satu sama lain. Acara ini berfungsi untuk memperkuat solidaritas di antara seluruh anggota perusahaan.:: Dengan terselenggaranya acara halal bihalal ini, PT Alfath Mulia Jaya berharap dapat terus menjaga semangat kebersamaan dan kekeluargaan di antara seluruh anggotanya. Perusahaan optimis kekompakan tim akan semakin solid dalam menghadapi tantangan bisnis ke depan.",
        sumber: "alfathmulia.com",
        tahun: "2024",
        references: [
            {}
        ],
        albums: [
            {
                id: 1,
                photo: hb241
            },
            {
                id: 2,
                photo: hb242
            },
        ]
    },
    {
        id: 2,
        image: bt24,
        title: "PT Alfath Mulia Jaya Berbagi Takjil di Terminal dan Lounge",
        body: "Memasuki bulan Ramadhan, PT Alfath Mulia Jaya menggelar kegiatan berbagi takjil kepada masyarakat. Pada tanggal 3-4 April 2024, perusahaan ini membagikan takjil di dua lokasi berbeda, yaitu Terminal Purabaya dan Grab Lounge Bungurasih, Surabaya.:: Di Terminal Purabaya, kegiatan berbagi takjil dilakukan di sisi bangunan titik antar kendaraan roda empat dan roda dua pada pukul 16.00 - 17.30 WIB. Para pengemudi dan penumpang kendaraan yang melintas di area tersebut dapat menerima takjil berupa kurma, air mineral, dan makanan ringan lainnya secara gratis.:: Sementara itu, di Grab Lounge Bungurasih, para pengemudi ojek online dan pengunjung yang singgah di lounge tersebut juga mendapatkan berbagai jenis takjil dari PT Alfath Mulia Jaya pada pukul yang sama, yaitu 16.00 - 17.30 WIB selama dua hari berturut-turut.:: Kegiatan berbagi takjil ini merupakan bentuk kepedulian PT Alfath Mulia Jaya terhadap masyarakat, terutama bagi mereka yang beraktivitas di jalan raya menjelang waktu buka puasa. Dengan menyediakan takjil gratis, diharapkan dapat sedikit meringankan beban sekaligus membantu menjaga energi bagi para pengemudi kendaraan yang hendak berbuka.:: Selain kepedulian sosial, kegiatan ini juga bertujuan untuk meningkatkan interaksi dan silaturahmi antara perusahaan dengan masyarakat luas. Para staf PT Alfath Mulia Jaya turut terlibat langsung dalam pembagian takjil, sehingga momen ini dapat dijadikan sarana untuk mendekatkan diri dengan masyarakat sekitar.:: Rangkaian kegiatan berbagi takjil di Terminal Purabaya dan Grab Lounge Bungurasih ini mendapat sambutan antusias dari masyarakat. PT Alfath Mulia Jaya berkomitmen untuk dapat terus menggelar kegiatan sosial serupa di masa mendatang sebagai wujud kontribusi nyata perusahaan kepada masyarakat.",
        sumber: "alfathmulia.com",
        tahun: "2024",
        references: [
            {}
        ],
        albums: [
            {
                id: 1,
                photo: bt241
            },
            {
                id: 2,
                photo: bt242
            },
            {
                id: 3,
                photo: bt243
            },
            {
                id: 4,
                photo: bt244
            },
            {
                id: 5,
                photo: bt245
            },
            {
                id: 6,
                photo: bt246
            },
            {
                id: 7,
                photo: bt247
            },
        ]

    },
    {
        id: 3,
        image: bdk24,
        title: "PT Alfath Mulia Jaya Sediakan Layanan Transportasi Online di Bandara Dhoho Kediri",
        body: "PT Alfath Mulia Jaya, perusahaan penyedia jasa transportasi online, akan menjalin kerjasama dengan Angkasa Pura I untuk menyediakan layanan angkutan sewa khusus bagi penumpang di Bandara Dhoho, Kediri. Kerjasama ini bertujuan untuk meningkatkan pelayanan dan kemudahan akses transportasi bagi para pengguna jasa Bandara Dhoho.:: Dalam kerjasama tersebut, PT Alfath Mulia Jaya berencana untuk menempatkan armada kendaraannya yang terdiri dari kendaraan roda empat seperti mobil penumpang dan kendaraan roda dua seperti sepeda motor di area kedatangan Bandara Dhoho. Calon penumpang nantinya dapat memesan layanan transportasi ini melalui aplikasi mobile yang disediakan atau melalui loket pemesanan di bandara.:: Hadirnya layanan transportasi online ini diharapkan dapat memudahkan penumpang untuk melanjutkan perjalanan mereka dari Bandara Dhoho menuju berbagai kawasan di Kediri dan sekitarnya. Selain itu, keberadaan opsi transportasi sewa khusus dari PT Alfath Mulia Jaya turut mendukung upaya pengembangan sektor pariwisata dan ekonomi kreatif di wilayah Kediri.:: Kerjasama antara PT Alfath Mulia Jaya dengan Angkasa Pura I di Bandara Dhoho Kediri ini dilakukan sebagai bentuk komitmen untuk terus meningkatkan pelayanan guna memenuhi kebutuhan para pengguna jasa bandara. Layanan transportasi yang aman, nyaman, dan terjangkau akan disediakan bagi pengguna Bandara Dhoho.:: Saat ini, PT Alfath Mulia Jaya sedang mempersiapkan soft launching kerjasama ini. Informasi lebih lanjut terkait tanggal peluncuran resmi layanan akan diumumikan kemudian. Masyarakat diharapkan dapat memanfaatkan layanan transportasi sewa khusus ini saat bepergian melalui Bandara Dhoho Kediri di masa mendatang.",
        sumber: "alfathmulia.com",
        tahun: "2024",
        references: [
            {}
        ],
        albums: [
            {
                id: 1,
                photo: bdk241
            },
            {
                id: 2,
                photo: bdk242
            },
        ]
    },
    {
        id: 4,
        image: bp24,
        title: "PT. Alfath Mulia Jaya Mendukung Program Pengembangan Pelayanan Berbasis IT dan Terintegrasi di Terminal Tipe A Purabaya",
        body: "Terminal Purabaya berinovasi dengan meluncurkan layanan Tiket Elektronik (E-Tiket) dan pengembangan sistem berbasis IT. PT. Alfath Mulia Jaya (AMJ) turut mendukung visi dan misi terminal untuk menciptakan \"Terminal yang Menyenangkan\" melalui layanan angkutan sewa khusus dan lounge eksklusif bagi penumpang.:: Pada 28 Juni 2024, Terminal Purabaya mengadakan sosialisasi pengembangan ini, yang dipimpin oleh Kepala Balai Pengelola Transportasi Darat kelas II Jawa Timur, Bapak Mangasi Sinaga, S.E., S.H., M.M., dan Pengawas Satuan Pelayanan Terminal Purabaya, Bapak Ahmad Badik, S.T., M.M. Sistem Tiket Terpusat (CTT) yang memungkinkan pembelian tiket online diperkenalkan untuk memberantas percaloan dan meningkatkan kenyamanan penumpang. Selain itu, terminal juga dilengkapi autogate dengan pemindaian QR Code dan sensor tangan.:: AMJ menyediakan angkutan sewa khusus untuk memenuhi kebutuhan transportasi personal dengan standar layanan tinggi. Layanan lounge eksklusif AMJ menawarkan kenyamanan ekstra dengan fasilitas modern seperti area duduk nyaman, akses Wi-Fi, dan layanan makanan dan minuman.:: Pengembangan fasilitas tambahan seperti park and ride, toilet dan locker VIP, taman baca, dan museum transportasi semakin melengkapi pengalaman penumpang di Terminal Purabaya. Visi terminal ini adalah menjadi tempat yang menyenangkan dan aman, sementara misinya adalah menyediakan layanan berbasis IT yang terintegrasi dan mendukung pemberdayaan UMKM.:: AMJ berkomitmen untuk mendukung terlaksananya program transformasi digital pada Terminal Tipe A Purabaya, sehingga Terminal Purabaya akan menjadi model terminal masa depan pertama yang mengutamakan teknologi dan kenyamanan, serta menjadikan pengalaman penumpang lebih efisien dan menyenangkan.",
        sumber: "alfathmulia.com",
        tahun: "2024",
        references: [
            {
                id: 1,
                url: "https://sidoarjosatu.com/transformasi-digital-pengelola-terminal-purabaya-bungurasih-bakal-realisasikan-e-tiket-dan-barcode/",
                text: "Transformasi Digital ; Pengelola Terminal Purabaya (Bungurasih) Bakal Realisasikan E-Tiket dan Barcode"
            },
            {
                id: 2,
                url: "https://www.potretrealita.com/2024/06/29/pengembangan-terminal-purabaya-melalui-pelayanan-berbasis-it/",
                text: "Pengembangan Terminal Purabaya, Melalui Pelayanan Berbasis IT"
            },
        ],
        albums: [
            {
                id: 1,
                photo: bp241
            },
            {
                id: 2,
                photo: bp242
            },
            {
                id: 3,
                photo: bp243
            },
            {
                id: 4,
                photo: bp244
            },
        ]
    },
    {
        id: 5,
        image: okt24,
        title: "PT. Alfath Mulia Jaya Bersinergitas dengan Satuan Pelayanan TTA Purabaya Dalam Peningkatan Fasilitas dan Pelayanan di Terminal Tipe A Purabaya",
        body: "PT. Alfath Mulia Jaya (AMJ) dengan bangga mendukung pengembangan Terminal Tipe A Purabaya dengan memberikan dukungan-dukungan kecil untuk meningkatkan pelayanan pengelolaan arus penumpang. Terminal Purabaya, sebagai salah satu pusat transportasi terbesar di Jawa Timur, memiliki visi untuk menyediakan layanan transportasi yang modern, nyaman, dan efisien bagi masyarakat​.:: Dengan dukungan dan support yang diberikan, sejalan dengan misi Terminal Purabaya yang berfokus pada keselamatan dan kenyamanan penumpang, diharapkan peningkatan pelayanan dapat berjalan secara maksimal. Mengingat Terminal Tipe A Purabaya merupakan pintu masuk sekaligus pintu keluar Jawa Timur yang menghubungkan ke berbagai daerah. :: Kontribusi AMJ dalam penyediaan fasilitas ini mencerminkan komitmen kami untuk terus mendukung upaya peningkatan infrastruktur publik. Kami percaya bahwa langkah ini dapat membantu Terminal Purabaya mencapai visinya, serta memberikan manfaat nyata bagi ribuan pengguna setiap harinya.",
        sumber: "alfathmulia.com",
        tahun: "2024",
        references: [
            {
                id: 1,
                url: "https://terminalpurabaya.id/",
                text: "Terminal Pura Baya"
            },
            {
                id: 2,
                url: "https://hubdat.dephub.go.id/id/bptd/jatim/satpel/terminal-tipe-a-purabaya/",
                text: "Hubdat - BPTD Jawa Timur"
            },
        ],
        albums: [
            {
                id: 1,
                photo: okt241
            },
            {
                id: 2,
                photo: okt242
            },
            {
                id: 3,
                photo: okt243
            },
        ]
    },
];

// Memisahkan teks body menjadi perparagraf
blogsData.forEach(blog => {
    blog.body = blog.body.split("::");
});